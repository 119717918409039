import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'

import { StoreService } from '@services/store/store.service'

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _store: StoreService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if(this._store.Get("Authorize") != null) {
            return true
        }
        else {
            this._router.navigateByUrl("login", {replaceUrl: true})
            return false
        }
    }
  
}
