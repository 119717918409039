import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

import { StoreService } from '@services/store/store.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttprequestService {
  constructor(private _http: HttpClient, private _store: StoreService) {}

  /**
   * Get Request header
   * @returns Header
   */
  private GetHeaders(isMultiPart: boolean = false): any {
    let headers;
    let authorize = this._store.Get('Authorize');
    if (isMultiPart) {
      headers = {
        Authorization: authorize ? 'Bearer ' + authorize : '',
      };
    } else {
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: authorize ? authorize : '',
      };
    }
    return headers;
  }

  /**
   * Get method
   * @param url : string
   * @param params ? : any
   * @returns Promise any
   */
  public Get(url: string): Promise<any> {
    return this._http
      .get(environment.apiUrl + url, { headers: this.GetHeaders() })
      .toPromise();
  }

  /**
   * Post method
   * @param url : string
   * @param params ? : any
   * @returns Promise any
   */
  public Post(endPoint: string, params: any): Observable<any> {
    return this._http
      .post(
        environment.apiUrl + endPoint,
        { ...params },
        { headers: this.GetHeaders() }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * Performs a post request
   * @param endPoint: string
   * @param params: any
   */
  public postMultiPart(endPoint: string, params: any): Observable<any> {
    let formDatas: FormData = new FormData();

    if (params instanceof FormData) {
      formDatas = params;
    } else {
      Object.keys(params).forEach((key) => {
        let fdValue = params[key];

        // Avoid null value to be considered as Object
        if (fdValue == null) {
          fdValue = '';
        }

        if (typeof params[key] === 'object' && fdValue != '') {
          Object.keys(params[key]).forEach((subKey) => {
            let fdSubValue = params[key][subKey];

            // Avoid null value to be considered as Object
            if (fdSubValue == null) {
              fdSubValue = '';
            }

            if (typeof params[key][subKey] === 'number')
              fdValue = params[key][subKey].toString().replace('.', ',');
            formDatas.append(key + '.' + subKey, fdSubValue);
          });
        }

        if (typeof params[key] === 'number')
          fdValue = params[key].toString().replace('.', ',');

        formDatas.append(key, fdValue);
      });
    }

    return this._http
      .post(environment.apiUrl + endPoint, formDatas, {
        headers: this.GetHeaders(true),
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * Put method
   * @param url : string
   * @param params ? : any
   * @returns Promise any
   */
  Put(url: string, params: any): Promise<any> {
    return this._http
      .put(
        environment.apiUrl + url,
        {
          ...params,
        },
        { headers: this.GetHeaders() }
      )
      .toPromise();
  }
}
