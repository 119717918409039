import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { NgxSpinnerModule } from "ngx-spinner"
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/fr'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { HttpRequestInterceptor } from '@interceptors/http.request.interceptor';

// Translation http loader
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json')
}

// Convert + in URL to prevent error
export default class CustomUrlSerializer implements UrlSerializer {
    private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer()

    parse(url: string): UrlTree {
        // Encode "+" to "%2B"
        url = url.replace(/\+/gi, '%2B')
        // Use the default serializer.
        return this._defaultUrlSerializer.parse(url)
    }

    serialize(tree: UrlTree): string {
        return this._defaultUrlSerializer.serialize(tree).replace(/\+/gi, '%2B');
    }
}

// Register fr locale to date
registerLocaleData(localeFr, 'fr')

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(
      {
        defaultLanguage: 'fr',
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      }
  ),
  ],
  exports: [
    TranslateModule,
  ],
  providers: [
    { 
      provide: UrlSerializer, 
      useClass: CustomUrlSerializer 
    },
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: HttpRequestInterceptor, 
      multi: true 
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
