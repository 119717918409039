<header class="row border-bottom white-bg">
    <nav class="navbar navbar-expand-lg navbar-static-top" role="navigation">

        <img 
            src="../assets/images/logo.png" 
            alt="Cipelia" 
            class="m-3 mb-2 mt-2 mr-auto logo"
            [routerLink]="['/home/noconformitylist']" />


        <div id="navbar" class="navbar-collapse">
            <ul class="nav navbar-right ml-auto">
                <li (click)="Translate('en')" class="btn btn-white" [ngClass]="currentLang == 'en' ? 'active' : ''">
                    En
                </li>
                <li (click)="Translate('fr')" class="btn btn-white ml-2" [ngClass]="currentLang == 'fr' ? 'active' : ''">
                    Fr
                </li>
            </ul>
            <div *ngIf="isLogged" ngbDropdown class="d-inline-block navbar-right">
                <button class="btn btn-white" id="userMenu" ngbDropdownToggle><i class="far fa-user"></i> {{ userEmail }}</button>
                <div ngbDropdownMenu aria-labelledby="userMenu">
                    <button ngbDropdownItem [routerLink]="['/home/account']">{{ "account.menu" | translate }}</button>
                    <button ngbDropdownItem (click)="Logout()">{{ "common.logout" | translate }}</button>
                </div>
            </div>
        </div>
    </nav>
</header>

<div class="container">
    <ul class="menu" *ngIf="isLogged">
        <li class="btn btn-white" [routerLink]="['/evaluation']">{{ "menu.evaluation" | translate }}</li>
        <li class="btn btn-white" [routerLink]="['/noconformitylist']">{{ "menu.noconformityrequest" | translate }}</li>
        <li class="btn btn-white" [routerLink]="['/compliance']">{{ "menu.compliance" | translate }}</li>
        <li class="btn btn-white" [routerLink]="['/consultations']">{{ "menu.consultations" | translate }}</li>
        <!-- <li class="btn btn-white" [routerLink]="['/referentials']">{{ "menu.referentials" | translate }}</li> -->
    </ul>
</div>

<div class="container">
    <router-outlet></router-outlet>
</div>

<ngx-spinner type = "ball-beat"></ngx-spinner>

