import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

    public salt: string = "ciPelIA"

    private _crypt = (text) => {
        const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0))
		const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2)
		const applySaltToChar = (code) => textToChars(this.salt).reduce((a, b) => a ^ b, code)

		return text
			.split("")
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join("")
    }

    private _decrypt = (encoded) => {
		if(encoded) {
			const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0))
			const applySaltToChar = (code) => textToChars(this.salt).reduce((a, b) => a ^ b, code)

			return encoded
				.match(/.{1,2}/g)
				.map((hex) => parseInt(hex, 16))
				.map(applySaltToChar)
				.map((charCode) => String.fromCharCode(charCode))
				.join("")			
		}
		else {
			return null
		}
	}

    constructor() { }

    /**
     * Get key's value from localStorage
     * @param key 
     * @returns Value
     */
    public Get(key: string): string {
        return this._decrypt(localStorage.getItem(this._crypt(key)))
    }    

    /**
     * Set key and value in localStorage
     * @param key 
     * @param value 
     */
    public Set(key: string, value: string): void {
        localStorage.setItem(this._crypt(key), this._crypt(value))
    }  
      
    /**
     * Clear localStorage
     */
     public Clear(): void {
        localStorage.clear()
    }  
}
