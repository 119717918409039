import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticatedGuard } from '@guards/authenticated.guard'
import { UnauthenticatedGuard } from '@guards/unauthenticated.guard'

const routes: Routes = [
    {
        path: "login",
        loadChildren: () => import('@app/auth/login/login.module').then( m => m.LoginModule),
        canActivate: [UnauthenticatedGuard]
    },
    {
        path: "forgottenpassword",
        loadChildren: () => import('@app/auth/forgottenpassword/forgottenpassword.module').then( m => m.ForgottenpasswordModule),
        canActivate: [UnauthenticatedGuard]
    },
    {
        path: "activate/:guid",
        loadChildren: () => import('@app/auth/activate/activate.module').then( m => m.ActivateModule),
        canActivate: [UnauthenticatedGuard]
    },
    {
        path: "",
        loadChildren: () => import('@app/home/home.module').then( m => m.HomeModule),
        canActivate: [AuthenticatedGuard]
    },
    {
        path: "**",
        redirectTo: ''
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
