export interface IUser {
    id: number
    email: string  
    lastName: string   
    firstName: string     
    phone: number        
    supplierId: number
    isReader: boolean
}

export class User {
    public id: number
    public email: string  
    public lastName: string   
    public firstName: string     
    public phone: number        
    public supplierId: number
    public isReader: boolean = false

    constructor(o: IUser) {
        this.id = o ? o.id : null
        this.email = o ? o.email : null
        this.lastName = o ? o.lastName : null
        this.firstName = o ? o.firstName : null
        this.phone = o ? o.phone : null
        this.supplierId = o ? o.supplierId : 0
        this.isReader = o ? o.isReader : false
    }
}