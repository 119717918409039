import { Component } from '@angular/core';
import { Router } from '@angular/router'

import { AuthService } from '@services/auth/auth.service';
import { StoreService } from '@services/store/store.service'
import { SupplierService } from '@services/supplier/supplier.service';
import { TranslationService } from '@services/translation/translation.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public title: string = "Front"
    public userEmail: string
    public isLogged: boolean = false
    public currentLang: string = "fr"
    public isReader: boolean = false
    public selectOptions: any[] = []
    public currentSupplier: number = 0

    constructor(
        private _auth: AuthService,
        private _translation: TranslationService,
        private _router: Router,
        private _store: StoreService,
        private _supplier: SupplierService
    ) { 
        
    }

    ngOnInit(): void {
        if(this._store.Get("Lang")) {
            this.Translate(this._store.Get("Lang"))
        }
        if(this._store.Get("UserId")) {
            this.isLogged = true
            this.userEmail = this._store.Get("UserEmail")
            this.isReader = this._store.Get("IsReader") === 'true'
            if(this.isReader) {
                this.currentSupplier = Number(this._store.Get("SupplierId"))
            }

            this._auth.GetLoggedUser()
                .then(
                    () => {

                        this._auth.ChangeLoggedState(this.isLogged)
                    }
                    )
            
            this._supplier.GetSuppliers()
            .then(
                data => {
                    this.selectOptions = data
                }
            )
        }
        this._auth.loggedEventEmitter.subscribe(
            data => {
                this.isLogged = data
            }
        )
        this._auth.userEventEmitter.subscribe(
            data => {
                this.userEmail = data.email
                this.isReader = data.isReader
                this.currentSupplier = data.supplierId
                this._store.Set("IsReader", data.isReader.toString())
                this._store.Set("SupplierId", data.supplierId.toString())
            }
        )
    }

    ngOnDestroy() {
        this._auth.loggedEventEmitter.unsubscribe()
        this._auth.userEventEmitter.unsubscribe()
    }

    public onSelectChange(event: any):void {
        this._store.Set("SupplierId", event.target.value)
        this._store.setSupplierId(event.target.value)
    }

    /**
     * Change lang
     * @param lang 
     */
    public Translate(lang: string):void {
        this.currentLang = lang
        this._store.Set("Lang", lang)
        this._translation.SetCurrentLanguage(lang)
    }

    /**
     * Logout
     */
    public Logout():void {
        this._store.Clear()
        this._auth.ChangeLoggedState(false)
        this._router.navigateByUrl("login", {replaceUrl: true})
    }
}
