import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { StoreService } from '@services/store/store.service'
import { AuthService } from '@services/auth/auth.service'


@Injectable({
    providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(
        private _auth: AuthService,
        private _router: Router,
        private _store: StoreService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    

        // let userId: string | null = this._store.getLocalStorage("UserId")
        let authToken: string | null = this._store.Get("Authorize")

        // adding headers to each request
        const clonedRequest = request.clone()


        return next.handle(clonedRequest)
            .pipe(
                tap(
                    event => {
                        // here you can do an event for each response
                        if (event instanceof HttpResponse) {
                        }
                    },
                    (error: any) => {
                        if(error.status == 401) {
                            this._auth.Logout()
                        }
                    }
                )
            )
    }
}