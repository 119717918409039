import { Injectable } from '@angular/core';
import { HttprequestService } from '@services/httprequest/httprequest.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(private _http: HttprequestService) { }

  public GetSuppliers(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this._http.Get('suppliers/suppliers/')
        .then(
          (resp) => {
            if (resp != null) {
              resolve(resp.map((o) => ({ value: o.id, label: o.name })))
            } else {
              reject({
                title: 'error.getsuppliers.title',
                message: 'error.getsuppliers.message'
              })
            }
          }
        )
        .catch(
          (error) => {
            reject({
              title: 'error.api.title',
              message: 'error.api.message'
            })
          }
        )
    })
  }
}
