import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { HttprequestService } from '@services/httprequest/httprequest.service';
import { StoreService } from '@services/store/store.service';

import { User } from '@models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedEventEmitter: EventEmitter<any> = new EventEmitter();
  public userEventEmitter: EventEmitter<any> = new EventEmitter();
  private _user: User;
  private _isLogged: boolean = false;

  constructor(
    private _router: Router,
    private _store: StoreService,
    private _http: HttprequestService
  ) {}

  /**
   * Log the User
   * @param login : User Email
   * @param password : User Password
   * @returns User logged in
   */
  public Login(login: string, password: string): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this._http
        .Post('users/login', { Login: login, Password: password })
        .subscribe(
          (resp) => {
            this._store.Set('Authorize', resp.token);
            this._store.Set('IsReader', resp.isReader.toString());
            let user = new User(resp);
            this._user = user;
            this.ChangeLoggedState(true);
            resolve(user);
          },
          (error) => {
            console.log(error);
            if (error.error.message == 'User not active') {
              reject({
                title: 'error.notActive.title',
                message: 'error.notActive.message',
              });
            }
            reject({
              title: 'error.login.title',
              message: 'error.login.message',
            });
          }
        );
    });
  }

  public GetLoggedUser(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this._http
        .Get('users/getbyid/' + this._store.Get('UserId'))
        .then((resp) => {
          let user = new User(resp);
          this._store.Set('IsReader', user.isReader.toString());
          this._user = user;
          this.userEventEmitter.emit(this._user);
          resolve(user);
        })
        .catch((error: any) => {
          if (error.error.message == 'User not active') {
            reject({
              title: 'error.notActive.title',
              message: 'error.notActive.message',
            });
          }
          reject({
            title: 'error.login.title',
            message: 'error.login.message',
          });
        });
    });
  }

  public GetLocalLoggedUser(): User {
    return this._user;
  }

  /**
   * Logout
   */
  public Logout(): void {
    this._store.Clear();
    this.ChangeLoggedState(false);
    this._router.navigateByUrl('login', { replaceUrl: true });
  }

  /**
   * Change Logged State in app
   * @param state
   */
  public ChangeLoggedState(state: boolean): void {
    this._isLogged = state;
    this.loggedEventEmitter.emit(this._isLogged);
    this.userEventEmitter.emit(this._user);
  }

  /**
   * Active the user | set password and first connection
   * @param login : User Email
   * @param password : User Password
   * @returns User logged in
   */
  public RequestUserActivation(
    login: string,
    password: string
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._http
        .Post('users/requestactivation', { Login: login, Password: password })
        .subscribe(
          (resp) => {
            resolve(resp);
          },
          (error) => {
            if (error.status == 404) {
              reject({
                title: 'error.unknownuser.title',
                message: 'error.unknownuser.message',
              });
            } else if (error.error.message == 'User is already active') {
              reject({
                title: 'error.userAlreadyActive.title',
                message: 'error.userAlreadyActive.message',
              });
            }

            reject({
              title: 'error.api.title',
              message: 'error.api.message',
            });
          }
        );
    });
  }

  /**
   * Activate the user
   * @param guid : User Guid
   * @returns User logged in
   */
  public ActivateUser(guid: string): Promise<Boolean> {
    return new Promise<Boolean>((resolve, reject) => {
      this._http.Post('users/activate/' + guid, {}).subscribe(
        (resp) => {
          resolve(resp);
        },
        (error) => {
          reject({
            title: 'error.activate.title',
            message: 'error.activate.message',
          });
        }
      );
    });
  }

  /**
   * Check if user already connect to the Front at least once
   * @param userEmail
   * @returns true or false
   */
  public CheckUserIsActive(userEmail: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._http
        .Get('users/isactive/' + userEmail)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error: any) => {
          if (error.status == 404) {
            reject({
              title: 'error.unknownuser.title',
              message: 'error.unknownuser.message',
            });
          }

          reject({
            title: 'error.api.title',
            message: 'error.api.message',
          });
        });
    });
  }
}
