import { Component } from '@angular/core';
import { Router } from '@angular/router'

import { AuthService } from '@services/auth/auth.service';
import { StoreService } from '@services/store/store.service'
import { TranslationService } from '@services/translation/translation.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public title: string = "Front"
    public userEmail: string
    public isLogged: boolean = false
    public currentLang: string = "fr"

    constructor(
        private _auth: AuthService,
        private _translation: TranslationService,
        private _router: Router,
        private _store: StoreService
    ) { 
        
    }

    ngOnInit(): void {
        if(this._store.Get("Lang")) {
            this.Translate(this._store.Get("Lang"))
        }
        if(this._store.Get("UserId")) {
            this.isLogged = true
            this.userEmail = this._store.Get("UserEmail")

            this._auth.GetLoggedUser()
                .then(
                    () => {

                        this._auth.ChangeLoggedState(this.isLogged)
                    }
                    )
                }
        this._auth.loggedEventEmitter.subscribe(
            data => {
                this.isLogged = data
            }
        )
        this._auth.userEventEmitter.subscribe(
            data => {
                this.userEmail = data.email
            }
        )
    }

    ngOnDestroy() {
        this._auth.loggedEventEmitter.unsubscribe()
        this._auth.userEventEmitter.unsubscribe()
    }

    /**
     * Change lang
     * @param lang 
     */
    public Translate(lang: string):void {
        this.currentLang = lang
        this._store.Set("Lang", lang)
        this._translation.SetCurrentLanguage(lang)
    }

    /**
     * Logout
     */
    public Logout():void {
        this._store.Clear()
        this._auth.ChangeLoggedState(false)
        this._router.navigateByUrl("login", {replaceUrl: true})
    }
}
