import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
    providedIn: 'root'
})

export class TranslationService {

    constructor(
        private _translate: TranslateService
    ) { }

    /**
     * Set current language
     * @param key string
     */
	public SetCurrentLanguage(lang: string): void {
		this._translate.setDefaultLang(lang)
	}

    /**
     * Get synchronous translate key
     * @param key string
     */
	public instant(key: string): string {
		return this._translate.instant(key) || ""
	}
}
